import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { IoMailOutline } from 'react-icons/io5'

import backgroundImg from '../images/why-choose-unicollege-background.png'

const initialFormErrorState = {
  hasError: false,
  msg: '',
  ctaButtonHTML: ''
}

const NewsletterSignUp = () => {
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false)
  const [formError, setFormError] = useState(initialFormErrorState)

  return (
    <StyledNewsletterSignUp>
      <Formik
        initialValues={{
          firstName: '',
          email: '',
          checkedLearningMethods: []
        }}
        validationSchema={
          Yup.object().shape({
            firstName: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
          })
        }
        onSubmit={(values, {resetForm}) => {
          // console.log(JSON.stringify(values))

          addToMailchimp(values.email, {
            FNAME: values.firstName,
            ...values.checkedLearningMethods.includes('Full Time') && { 'group[80825][1]': true },
            ...values.checkedLearningMethods.includes('Part Time') && { 'group[80825][2]': true },
            ...values.checkedLearningMethods.includes('Online') && { 'group[80825][4]': true },
            ...values.checkedLearningMethods.includes('Workshops') && { 'group[80825][16384]': true },
          })
            .then(({result, msg}) => {
              if (result === 'success') {
                setFormError(initialFormErrorState)
                setSuccessfullySubmitted(true)
                resetForm()
              } else {
                const anchorTagStartIndex = msg.indexOf('<a ')

                setFormError({
                  hasError: true,
                  msg: anchorTagStartIndex !== -1 ? msg.slice(0, anchorTagStartIndex) : msg,
                  ctaButtonHTML: anchorTagStartIndex !== -1 ? msg.slice(anchorTagStartIndex) : ''
                })
              }
            })
            .catch(error => {
              alert(error) // 
            })

        }}
      >
        {({errors, touched}) => (
          <Form>
            <IoMailOutline id='mail-icon' />
            <h3>Sign up for our newsletter</h3>

            <span>
              <label htmlFor="firstName">First Name</label>
              <Field id="firstName" name="firstName"  />
              {touched.firstName && errors.firstName && <p className="error-message">{errors.firstName}</p>}
            </span>

            <span>
              <label htmlFor="email">Email</label>
              <Field id="email" name="email" type="email" />
              {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
            </span>

            <h4 id="checkbox-group">Which courses would you like more news & info about?</h4>

            <div role="group" aria-labelledby="checkbox-group">
              <span className="checkbox-input">
                <Field type="checkbox" id="full-time-checkbox" name="checkedLearningMethods" value="Full Time" />
                <label htmlFor="full-time-checkbox">Full Time</label>
              </span>
              <span className="checkbox-input">
                <Field type="checkbox" id="part-time-checkbox" name="checkedLearningMethods" value="Part Time" />
                <label htmlFor="part-time-checkbox">Part Time</label>
              </span>
              <span className="checkbox-input">
                <Field type="checkbox" id="online-checkbox" name="checkedLearningMethods" value="Online" />
                <label htmlFor="online-checkbox">Online</label>
              </span>
              <span className="checkbox-input">
                <Field type="checkbox" id="workshops-checkbox" name="checkedLearningMethods" value="Workshops" />
                <label htmlFor="workshops-checkbox">Workshops</label>
              </span>
            </div>

            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>

      {successfullySubmitted && <p className='form-notification'>Thank you for signing up to our newsletter!</p>}
      {formError.hasError && <p className='form-notification'>{formError.msg}</p>}
      {formError.ctaButtonHTML && <div dangerouslySetInnerHTML={{__html: formError.ctaButtonHTML}} />}

      <img src={backgroundImg} alt="" />
    </StyledNewsletterSignUp>
  )
}

const StyledNewsletterSignUp = styled.div`
  position: relative;
  padding: 1rem;

  #mail-icon {
    display: block;
    color: #f4f4f4;
    margin: 0 auto;
    font-size: 4rem;
  }

  h3 {
    color: #f4f4f4;
    max-width: 20rem;
    margin: 0 auto 1rem auto;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.25;
    text-align: center;
  }

  h4 {
    color: #fafafa;
    margin: 1rem 0 0.5rem 0;
    font-weight: normal;
  }

  form > span {
    display: block;
    margin: 0.75rem 0;
  }

  div[role='group'] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
  }

  span {
    label {
      display: block;
      color: #f4f4f4;
      margin-bottom: 0.25rem;
    }

    input:not([type='checkbox']), 
    textarea,
    select {
      width: 100%;
      padding: 0.2rem;
      font-size: 1.1rem;
      font-family: inherit;
      border: solid 1px #ccc;
    }

    input[type='checkbox'] {
      margin-right: 1rem;
      width: 1.6rem;
      height: 1.6rem;
    }

    /* option[value=''] {
      color: #555;
    } */

    p.error-message {
      color: lime;
      margin-top: 0.4rem;
    }

    &.checkbox-input, &.radio-input {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      label {
        display: initial;
        margin-bottom: 0;
      }

      input {
        flex: 0 0 auto;
      }

      p.error-message {
        flex-basis: 100%;
      }
    }

    &.error {
      input, select {
        border-color: red;
      }
    }
  }

  input[type='submit'], button[type='submit'], button.form-btn {
    display: block;
    background-color: #f4f4f4;
    color: var(--primary-color);
    width: fit-content;
    margin: 1.5rem auto 0 auto;
    padding: 0.6em 2em;
    font-size: 1rem;
    text-transform: uppercase;
    border: none;
  }

  p.form-notification {
    color: #f4f4f4;
    margin-top: 2rem;
    padding: 0.4em 0.6em;
    border: solid 1px #f4f4f4;
    border-radius: 5px;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
`

export default NewsletterSignUp
