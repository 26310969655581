import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import formatLearningMethodTitle from '../../helpers/formatLearningMethodTitle'

const LearningMethodButtons = ({ learningMethods}) => (
  <StyledLearningMethodButtons>
    {learningMethods.map(method => (
      <Link key={method.slug.current} to={`/learning-methods/${method.slug.current}/`} style={{background: method.themeColour}}>
        {formatLearningMethodTitle(method.title)}
      </Link>
    ))}
  </StyledLearningMethodButtons>
)

const StyledLearningMethodButtons = styled.section`
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 3rem;

  a {
    display: inline-block;
    background: var(--primary-color);
    color: #fafafa;
    width: 20rem;
    min-width: 200px;
    padding: 0.8em 0;
    border-radius: 10px;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &.blue { background-color: #312668; }
    &.red { background-color: var(--primary-color); }
    &.orange { background-color: #F35302; }
    &.grey { background-color: #767676; }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    gap: 1rem;
    padding: 2rem 1rem;

    a {
      font-size: 1.25rem;
    }
  }
`

export default LearningMethodButtons
