import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// import Accreditations from "../components/Accreditations"
import ChooseYourCourse from "../components/home-page/ChooseYourCourse";
import HomeCardLinks from "../components/home-page/HomeCardLinks";
import LatestNews from "../components/LatestNews";
import LearningMethodButtons from "../components/home-page/LearningMethodButtons";
import LearningMethodButtonsAlt from "../components/home-page/LearningMethodButtonsAlt";
import NewsletterSignUp from "../components/NewsletterSignUp";
import Seo from "../components/Seo";
import Showcase from "../components/home-page/Showcase";
import TestimonialsCarousel from "../components/home-page/TestimonialsCarousel";
import UpcomingCourses from "../components/UpcomingCourses";
import WhyChooseUnicollege from "../components/home-page/WhyChooseUnicollege";

const IndexPage = ({ data }) => {
  const showcaseSlides = data.allSanityShowcaseSlide.nodes;
  const learningMethods = data.allSanityLearningMethod.nodes;
  const studyFields = data.allSanityStudyField.nodes;
  const testimonials = data.allSanityTestimonial.nodes;

  return (
    <StyledIndexPage>
      <Seo title="Home" description={data.sanitySiteSettings.description} pathname="/">
        <script id="mcjs">
          {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/73c9eef377eb438fcfa567cdf/d9565cfccbda05e8fcb3e3552.js");`}
        </script>
      </Seo>

      <Showcase slides={showcaseSlides} />
      <LearningMethodButtons learningMethods={learningMethods} />
      <WhyChooseUnicollege />
      <ChooseYourCourse learningMethods={learningMethods} studyFields={studyFields} />
      <LearningMethodButtonsAlt learningMethods={learningMethods} />
      <HomeCardLinks />
      <TestimonialsCarousel testimonials={testimonials} />

      <section className="component-group">
        <UpcomingCourses />
        <LatestNews />
        <NewsletterSignUp />
      </section>

      {/* <Accreditations /> */}
    </StyledIndexPage>
  );
};

export const query = graphql`
  query HomePageQuery {
    allSanityShowcaseSlide(
      filter: { _id: { glob: "!drafts*" } }
      sort: { fields: order }
    ) {
      nodes {
        order
        backgroundImage {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
        mobileBackgroundImage {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
        _rawContent
        contentAlignment
        textAlign
        linkButton {
          text
          link {
            linkType
            internalLink {
              current
            }
            href
            blank
          }
        }
      }
    }
    allSanityLearningMethod(sort: { fields: rank }) {
      nodes {
        title
        slug {
          current
        }
        themeColour
        shortDescription
      }
    }
    allSanityStudyField(sort: { fields: title, order: ASC }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    sanitySiteSettings {
      description
    }
    allSanityTestimonial {
      nodes {
        _id
        studentName
        review
      }
    }
  }
`;

const StyledIndexPage = styled.main`
  section.component-group {
    display: flex;
    flex-flow: row wrap;
    gap: 1.75rem;
    max-width: 1500px;
    margin: 2rem auto;
    padding: 0 1rem;

    & > * {
      flex: 1 1 400px;
    }
  }
`;

export default IndexPage;
